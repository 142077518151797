import { createContext, useContext, useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useRouter } from 'next/router';
import UserContext from './UserContext';
import dynamic from 'next/dynamic';
import { postCheckUser } from "../src/dataProvider";
import { useIdleTimer } from 'react-idle-timer';
const keycloakJson = dynamic(() => import(`../public/keycloak/${process.env.keycloak}`));


const AuthContext = createContext({});
const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null;

export const AuthProvider = ({ children }) => {
    const router = new useRouter();
    const [loading, setLoading] = useState(true);
    const [failedAuth, setFailedAuth] = useState(false);
    const [idle, setIdle] = useState(false);
    let { keycloakstate, setKeycloakstate } = useContext(UserContext);

    // set 'idle' after 30 minutes of inactivity
    useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: () => setIdle(true),
        onActive: () => setIdle(false),
        debounce: 500
    });

    const getAuthentication = async () => {
        let keycloakId;
        let email;
        let lastName;
        let firstName;
        let gemiNumber;
        let isAuthenticated = false;

        const keycloak = Keycloak("../../keycloak/" + process.env.keycloak);

        if (pageRequiresAuth()) {
            await keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
                setKeycloakstate({
                    keycloak: keycloak,
                    authenticated: authenticated,
                    token: keycloak.token,
                    userName: keycloak.tokenParsed?.name,
                    user_id: keycloak.tokenParsed?.sub,
                    roles: keycloak.tokenParsed?.realm_access?.roles
                });
                if (authenticated) {
                    sessionStorage.setItem('authentication', keycloak.token);
                    sessionStorage.setItem('refreshToken', keycloak.refreshToken);

                    keycloakId = keycloak.tokenParsed.sub;
                    email = keycloak.tokenParsed.email;
                    lastName = keycloak.tokenParsed.family_name;
                    firstName = keycloak.tokenParsed.given_name;
                    gemiNumber = keycloak.tokenParsed.gemi_number;

                    isAuthenticated = true;
                } else {
                    var currentURL = window.location.protocol + "//" + window.location.host + '/unauthorizedAccess';
                    window.location =
                        keycloakJson["auth-server-url"] +
                        '/realms/' +
                        keycloakJson["realm"] +
                        '/protocol/openid-connect/logout?redirect_uri=' +
                        currentURL;
                }
            });
        } else if (!failedAuth) { // try automatic auth if it hasnt failed before
            await keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
                setKeycloakstate({
                    keycloak: keycloak,
                    authenticated: authenticated,
                    token: keycloak.token,
                    userName: keycloak.tokenParsed?.name,
                    user_id: keycloak.tokenParsed?.sub,
                    roles: keycloak.tokenParsed?.realm_access?.roles
                });
                if (authenticated) {
                    sessionStorage.setItem('authentication', keycloak.token);
                    sessionStorage.setItem('refreshToken', keycloak.refreshToken);

                    keycloakId = keycloak.tokenParsed.sub;
                    email = keycloak.tokenParsed.email;
                    lastName = keycloak.tokenParsed.family_name;
                    firstName = keycloak.tokenParsed.given_name;
                    gemiNumber = keycloak.tokenParsed.gemi_number;

                    isAuthenticated = true;
                } else {
                    setFailedAuth(true);
                }
            });
        }

        return { keycloakId, email, lastName, firstName, gemiNumber, authenticated: isAuthenticated };
    };

    const pageRequiresAuth = () => {
        let authCheck = false;
        const securePages = [
            "/questions/add",
            "/questions/edit/[id]",
            "/management",
            "/parametricFiles",
            "/logIn",
        ];
        if (securePages.includes(router.pathname)) {
            authCheck = true;
        }
        return authCheck;
    };

    const createUserSession = async (userInfos) => {
        const sessionUserInfo = {
            id: userInfos.id,
            lastName: userInfos.lastName,
            firstName: userInfos.firstName,
            isChamberUser: userInfos.isChamberUser,
            isAdmin: userInfos.isAdmin,
            companyId: userInfos.companyId,
            keycloakId: userInfos.keycloakId
        };
        sessionStorage.setItem('userSession', JSON.stringify(sessionUserInfo));

        return true;
    };

    // help function used in effect below
    const updateToken = async () => {
        // dont run any logic if logged out
        if (keycloakstate.authenticated) {
            // if user is not idle, update token
            if (!idle) {
                try {
                    // update the token if it has less than 5 mins life
                    await keycloakstate.keycloak.updateToken(5 * 60);
                    // update the state
                    setKeycloakstate({
                        ...keycloakstate,
                        token: keycloakstate.keycloak.token,
                        userName: keycloakstate.keycloak.tokenParsed.name,
                        user_id: keycloakstate.keycloak.tokenParsed.sub,
                        roles: keycloakstate.keycloak.tokenParsed.realm_access.roles
                    });
                } catch (err) {
                    console.log(err);
                }
            } else {// if idle, just log out
                sessionStorage.removeItem('authentication');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('userSession');

                var currentURL = window.location.protocol + "//" + window.location.host;
                const keycloakJson = (await import(`../public/keycloak/${process.env.keycloak}`)).default;
                window.location =
                    keycloakJson["auth-server-url"] +
                    "/realms/" +
                    keycloakJson["realm"] +
                    "/protocol/openid-connect/logout?redirect_uri=" +
                    currentURL;
            }
        }
    };

    const tryAuth = async () => {
        setLoading(true);

        if (window !== undefined && keycloakstate.authenticated !== true) {
            let { keycloakId, email, lastName, firstName, gemiNumber, authenticated } = await getAuthentication();
            if (authenticated) {
                const userInfos = await postCheckUser(keycloakId, email, lastName, firstName, gemiNumber);
                await createUserSession(userInfos);
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(async () => {
        if (router.pathname) tryAuth();
    }, [router.pathname]);

    // try to update keycloak token every minute
    useEffect(() => {
        // if keycloak is loaded, try to update token every minute
        if (keycloakstate?.keycloak?.updateToken && setKeycloakstate) {
            const interval = setInterval(() => updateToken(), 60 * 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [keycloakstate, setKeycloakstate, idle]);

    // update keycloak session when keycloak data changes
    useEffect(() => {
        if (keycloakstate?.keycloak && keycloakstate?.token) {
            sessionStorage.setItem('authentication', keycloakstate.token);
            sessionStorage.setItem('refreshToken', keycloakstate.keycloak.refreshToken);
        }
    }, [keycloakstate]);

    return (
        <AuthContext.Provider value={{ loading }}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
    const { loading } = useAuth();
    if (loading) {
        return <LinearProgress />;
    }
    return children;
};